import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setMessage } from '../../store/slices/message'
import CurdTokenervice from '../../services/axios/CurdTokenervice'


export const createReview = createAsyncThunk(
    'reviews/insertReviews', async (arg, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        const { token, data } = arg
        try {
            const res = await CurdTokenervice.create(token, "review", data);
            return (res.data)
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })


export const getReviewsTitle = createAsyncThunk('reviews/getRevTitel', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { token, gredId, subjectId } = arg
    try {
        const res = await CurdTokenervice.getCourseTitles(token, "coursestitle", gredId, subjectId);
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})

export const getAllReviews = createAsyncThunk('reviews/getAllReviews', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { token, gredId, subjectId } = arg
    try {
        const res = await CurdTokenervice.getDtatByGrade(token, "getreviewbygrade", gredId, subjectId);
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})

export const updateReview = createAsyncThunk(
    'reviews/updtateReviews', async (arg, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        const { token, reviewId, data } = arg
        try {
            const res = await CurdTokenervice.updateData(token, "review", reviewId, data);
            return (res.data)
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })

export const deleteReview = createAsyncThunk(
    'reviews/deleteReviews', async (arg, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        const { token, reviewId } = arg
        try {
            const res = await CurdTokenervice.delData(token, "review", reviewId);
            return (res.data)
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })

const ReviewByGradeSlice = createSlice({
    name: 'reviews',
    initialState: { isloading: false, reviews: [], message: '', couresTitles: [] },
    reducers: {
        delAllReviews: (state, action) => {
            state.message = ''
            state.reviews = []
            state.couresTitles = []
        },
        delMessage: (state, action) => {
            state.message = ''
        }
    },
    extraReducers: {
        // Get Review Title
        [getReviewsTitle.pending]: (state, action) => {
            state.isloading = true
            state.message = ''
        },
        [getReviewsTitle.fulfilled]: (state, action) => {
            state.isloading = false;
            state.couresTitles = action.payload;
            state.message = ''
        },
        [getReviewsTitle.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },

        // Get All Reviews
        [getAllReviews.pending]: (state, action) => {
            state.isloading = true
            state.message = ''
        },
        [getAllReviews.fulfilled]: (state, action) => {
            state.isloading = false;
            state.reviews = action.payload;
            state.message = ''
        },
        [getAllReviews.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },

        //Insert Review
        [createReview.pending]: (state, action) => {
            state.isloading = true
        },
        [createReview.fulfilled]: (state, action) => {
            state.isloading = false;
            state.message = action.payload.message
           // state.reviews = state.reviews[0].reviews.push(action.payload.data)
        },
        [createReview.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },

        //Update Review
        [updateReview.pending]: (state, action) => {
            state.isloading = true
        },
        [updateReview.fulfilled]: (state, action) => {
            state.isloading = false;
            state.message = action.payload.message

        },
        [updateReview.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },

        //Delete Review
        [deleteReview.pending]: (state, action) => {
            state.isloading = true
        },
        [deleteReview.fulfilled]: (state, action) => {
            state.isloading = false;
            state.message = action.payload.message
           // state.reviews = state.reviews[0].reviews.filter(el => el.review_id != action.payload.review_id)
        },
        [deleteReview.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        }, 

    }
})


export const { delAllReviews, delMessage } = ReviewByGradeSlice.actions
export default ReviewByGradeSlice.reducer