import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CurdService from './../../admin/adminServices/curd.services'
import { setMessage } from '../../store/slices/message'

export const getAllImgCatogeryData = (createAsyncThunk('imgCatogryData/getAll', async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    try {
        const res = await CurdService.getAll("imgcatdata")
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
}))


export const getImgCatogeryData = createAsyncThunk(
    'imgCatogryData/getById', async (id, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        try {
            const res = await CurdService.get("imgcatdata", id);
            localStorage.setItem("catId", JSON.stringify(id))
            localStorage.setItem("catData", JSON.stringify(res.data))
            return res.data
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })

export const getCustmImgCatData = createAsyncThunk(
    'imgCatogryData/getCustmImg', async (id, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        try {
            const res = await CurdService.get("imgcatdata", id)
            return res.data
            
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }

    })

export const clearImgCatData = createAsyncThunk('imgCatogryData/clearImgs', async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    try {
        return []
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})

const imgCatogryDataSlice = createSlice({
    name: 'imgCatogryData',
    initialState: { isloading: false, img: [] },
    extraReducers: {
        // Get All Images
        [getAllImgCatogeryData.pending]: (state, action) => {
            state.isloading = true
        },
        [getAllImgCatogeryData.fulfilled]: (state, action) => {
            state.isloading = false;
            if (Object.keys(action.payload).length !== 0) {
                state.img = action.payload;
            }
        },
        [getAllImgCatogeryData.rejected]: (state, action) => {
            state.isloading = false
        },
        // Get One Images
        [getImgCatogeryData.pending]: (state, action) => {
            state.isloading = true
        },
        [getImgCatogeryData.fulfilled]: (state, action) => {
            state.isloading = false;
            if (action.payload.length !== 0) {
                state.img = action.payload;
            }
        },
        [getImgCatogeryData.rejected]: (state, action) => {
            state.isloading = false
        },
        // Get getCustmImg 
        [getCustmImgCatData.pending]: (state, action) => {
            state.isloading = true
        },
        [getCustmImgCatData.fulfilled]: (state, action) => {
            state.isloading = false;
            state.img = action.payload;
        },
        [getCustmImgCatData.rejected]: (state, action) => {
            state.isloading = false
        },
    }
})

export default imgCatogryDataSlice.reducer