import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CurdService from '../../admin/adminServices/curd.services'
import { setMessage } from './message'

export const getImgSectionCatgorey = (createAsyncThunk('imgSectionCat/getAll', async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    try {
        const res = await CurdService.getAll("imgcatsection");
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
}))


const imgCatBySectionSlice = createSlice({
    name:'imgSectionCatgorey',
    initialState:{isloading:false,img:[]},
    extraReducers:{
         // Get All Images
         [getImgSectionCatgorey.pending]:(state, action)=>{
            state.isloading = true
        },
        [getImgSectionCatgorey.fulfilled]:(state, action)=>{
           state.isloading = false;
          if(Object.keys(action.payload).length !== 0 ){
             state.img = action.payload; 
          }
        },
        [getImgSectionCatgorey.rejected]:(state, action)=>{
            state.isloading = false
    },
}
})

export default imgCatBySectionSlice.reducer