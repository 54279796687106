import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import CurdService from './../../admin/adminServices/curd.services'
import { setMessage } from '../../store/slices/message'

const modle = "imgsections"

export const retrieveImgSections = createAsyncThunk(
  'imgSection/getImgs', async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    try {
      const res = await CurdService.getAll(modle);
      return res.data
    } catch (error) {
      const message = error.message
      thunkAPI.dispatch(setMessage(message))
      return rejectWithValue(error.message)
    }
  })


export const deleteImgSection = createAsyncThunk(
  'imgSection/delImg', async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    try {
      const res = await CurdService.remove(modle, `/${id}`)
      return ("OK")
    } catch (error) {
      const message = error.message
      thunkAPI.dispatch(setMessage(message))
      return rejectWithValue(error.message)
    }
  })

export const retrieveImgSectionById = createAsyncThunk(
  'imgSection/retrieveImg', async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    try {
      const res = await CurdService.get(modle, id);
      return (res.data)

    } catch (error) {
      const message = error.message
      thunkAPI.dispatch(setMessage(message))
      return rejectWithValue(error.message)
    }
  })

export const updateImgSection = createAsyncThunk(
  'imgSection/updateImg', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    try {
      const res = await CurdService.update(modle, arg.id, arg.data);
      return (res.data)

    } catch (error) {
      const message = error.message
      thunkAPI.dispatch(setMessage(message))
      return rejectWithValue(error.message)
    }
  })
  
const imgSectionSlice = createSlice({
  name: 'imgSection',
  initialState: { isloading: false, img: [], message: '' },
  reducers:{
    delMessage:(state, action)=>{
       state.message = ''
    }
  },
  extraReducers: {
    // Get All ImgSection
    [retrieveImgSections.pending]: (state, action) => {
      state.isloading = true
    },
    [retrieveImgSections.fulfilled]: (state, action) => {
      state.isloading = false;
      if (Object.keys(action.payload).length !== 0) {
        state.img = action.payload;
      }
    },
    [retrieveImgSections.rejected]: (state, action) => {
      state.isloading = false
      state.message = action.payload
    },
    // delete ImgSection
    [deleteImgSection.pending]: (state, action) => {
      state.isloading = true
    },
    [deleteImgSection.fulfilled]: (state, action) => {
      state.isloading = false;
      state.img = state.img.filter(item => item.id !== action.payload.id)
    },
    [deleteImgSection.rejected]: (state, action) => {
      state.isloading = false
      state.message = action.payload
    },
    //retrieve One ImgSection By Id
    [retrieveImgSectionById.pending]: (state, action) => {
      state.isloading = true
    },
    [retrieveImgSectionById.fulfilled]: (state, action) => {
      state.isloading = false;
      if (Object.keys(action.payload).length !== 0) {
        state.img = action.payload;
      }
    },
    [retrieveImgSectionById.rejected]: (state, action) => {
      state.isloading = false
      state.message = action.payload
    },
    //Update ImgSection 
    [updateImgSection.pending]: (state, action) => {
      state.isloading = true
    },
    [updateImgSection.fulfilled]: (state, action) => {
      state.isloading = false;
      state.message = action.payload.message
    },
    [updateImgSection.rejected]: (state, action) => {
      state.isloading = false
      state.message = action.payload
    },
  }
})

export const {delMessage} = imgSectionSlice.actions
export default imgSectionSlice.reducer