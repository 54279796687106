import { configureStore } from '@reduxjs/toolkit'
import { loadingBarReducer } from 'react-redux-loading-bar'

import {
  authSlice,
  messageSlice,
  imgCatogryData, imgListData,
  imgSections, imgCatogery,
  imgCatBySection, imgData,
  pagehistory, studentDegrees,
  studentMarks, infoImage,
  countvisit,
  loginData,
  imgTestimonials,
  imgVides,
  imgCertificate,
  courses,
  topics,
  quizzes,
  coursesByGrade,
  subpartByGrade,
  vocabularyByGrade,
  reviewByGrade,
  quizbygrade,
  questionsbyquiz,
} from './slices'


const reducer = {
  auth: authSlice,
  message: messageSlice,
  imgCatogryData,
  imgListData,
  loadingBar: loadingBarReducer,
  imgSections,
  imgCatogery,
  imgCatBySection,
  imgData,
  pagehistory,
  studentDegrees,
  studentMarks,
  infoImage,
  countvisit,
  loginData,
  imgTestimonials,
  imgVides,
  imgCertificate,
  courses,
  topics,
  quizzes,
  coursesByGrade,
  subpartByGrade,
  vocabularyByGrade,
  reviewByGrade,
  quizbygrade,
  questionsbyquiz,
}

const store = configureStore({
  reducer: reducer,
  devTools: true,
})

export default store