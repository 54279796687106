import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CurdTokenervice from '../../services/axios/CurdTokenervice'
import { setMessage } from './message'

export const getinfoImage = createAsyncThunk('infoImage/getinfoImage', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const {token, secid, classid } = arg
    try {
        const res = await CurdTokenervice.getinfoImage(token,secid, classid);
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})

const infoImageSlice = createSlice({
    name: 'classTableImage',
    initialState: { isloading: false, img: [], message: '' },
    reducers: {
        delInfoMessage: (state, action) => {
            state.message = ''
        }
    },
    extraReducers: {
        // Get stutent Dgree
        [getinfoImage.pending]: (state, action) => {
            state.isloading = true
            state.message = ''
        },
        [getinfoImage.fulfilled]: (state, action) => {
            state.isloading = false;
            state.img = action.payload;
            state.message = ''
        },
        [getinfoImage.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },
    }
})


export const {delInfoMessage} = infoImageSlice.actions
export default infoImageSlice.reducer