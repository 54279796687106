import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setMessage } from '../../store/slices/message'
import CurdTokenervice from '../../services/axios/CurdTokenervice'


export const getCourse = createAsyncThunk('course/getCourse', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { token, courseId, gredId, termId, subjectId } = arg
    try {
        const res = await CurdTokenervice.getCourses(token, "course", courseId, gredId, termId, subjectId);
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})

const coursesSlice = createSlice({
    name: 'courses',
    initialState: { isloading: false, courses: [], message: '' },
    reducers: {
        delCourses: (state, action) => {
            state.message = ''
        }
    },
    extraReducers: {
        // Get Courses
        [getCourse.pending]: (state, action) => {
            state.isloading = true
            state.message = ''
        },
        [getCourse.fulfilled]: (state, action) => {
            state.isloading = false;
            state.courses = action.payload;
            state.message = ''
        },
        [getCourse.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },
    }
})

export const { delCourses } = coursesSlice.actions
export default coursesSlice.reducer