import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CurdService from './../../admin/adminServices/curd.services'
import { setMessage } from '../../store/slices/message'

export const getCertificate = createAsyncThunk(
    'imgCertificate', async (id = 5, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        try {
            const res = await CurdService.get("imgcatdata", id);
            return res.data
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })


    const imgCertificate= createSlice({
        name: 'imgTestimonials',
        initialState: { isloading: false, img: [] },
        extraReducers: {
            // Get Testimonials Images
            [getCertificate.pending]: (state, action) => {
                state.isloading = true
            },
            [getCertificate.fulfilled]: (state, action) => {
                state.isloading = false;
                if (Object.keys(action.payload).length !== 0) {
                    state.img = action.payload;
                }
            },
            [getCertificate.rejected]: (state, action) => {
                state.isloading = false
            },
        }
    })

    export default imgCertificate.reducer