import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setMessage } from '../../store/slices/message'
import CurdTokenervice from '../../services/axios/CurdTokenervice'

export const createQuestion = createAsyncThunk(
    'questions/insertquestions', async (arg, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        const { token, data } = arg
        try {
            const res = await CurdTokenervice.create(token, "question", data);
            return (res.data)
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })

export const updateQuestion = createAsyncThunk(
    'questions/updtatequestion', async (arg, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        const { token, questionId, courseId, quizId, gradeId, subjectId, termId, data } = arg
        try {
            const res = await CurdTokenervice.updateQueson(token, questionId, courseId, quizId, gradeId, subjectId, termId, data);
            return (res.data)
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })


export const getQuizQuestions = createAsyncThunk('questions/getquestions', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { token, quizId, courseId, gredId, subjectId, termId } = arg
    try {
        const res = await CurdTokenervice.getQuizQuesData(token, quizId, courseId, gredId, subjectId, termId);
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})

export const deleteQuestion = createAsyncThunk(
    'questions/delquestion', async (arg, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        const { token, questionId, courseId, quizId, gradeId, subjectId, termId } = arg
        try {
            const res = await CurdTokenervice.delQuestion(token, questionId, courseId, quizId, gradeId, subjectId, termId);
            return (res.data)
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })

export const getNewQuestionId = createAsyncThunk('questions/getquestionId', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { token, courseId, quizId, gradeId, subjectId, termId } = arg
    try {
        const res = await CurdTokenervice.getQuestionId(token, courseId, quizId, gradeId, subjectId, termId);
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})

export const getAnswers = createAsyncThunk('questions/getanswers', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { token, questionId, courseId, quizId, gradeId, subjectId, termId } = arg
    try {
        const res = await CurdTokenervice.getAnswer(token, questionId, courseId, quizId, gradeId, subjectId, termId);
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})

export const deleteAnswer = createAsyncThunk(
    'questions/delanswer', async (arg, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        const { token, id } = arg
        try {
            const res = await CurdTokenervice.delData(token, 'answer', id);
            return (res.data)
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })

export const createAnswer = createAsyncThunk(
    'questions/insertanswer', async (arg, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        const { token, data } = arg
        try {
            const res = await CurdTokenervice.create(token, "answer", data);
            return (res.data)
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })

export const updateAnswer = createAsyncThunk(
    'questions/updtateanswer', async (arg, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        const { token, id, data } = arg
        try {
            const res = await CurdTokenervice.updateData(token, 'answer', id, data);
            return (res.data)
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })
const questionsByQuizSlice = createSlice({
    name: 'questions',
    initialState: { isloading: false, questions: [], answers: [], message: '', quizTitles: [], questionId: [] },
    reducers: {
        delAllQuestions: (state, action) => {
            state.message = ''
            state.questions = []
            state.questionId = []
            state.quizTitles = []
            state.answers = []

        },
        delMessage: (state, action) => {
            state.message = ''
        }
    },
    extraReducers: {
        // Get Quizzes Title
        [getQuizQuestions.pending]: (state, action) => {
            state.isloading = true
            state.message = ''
        },
        [getQuizQuestions.fulfilled]: (state, action) => {
            state.isloading = false;
            state.quizTitles = action.payload;
            state.message = ''
            state.questions = action.payload.questions
        },
        [getQuizQuestions.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },
        // Get Quizzes Title
        [getAnswers.pending]: (state, action) => {
            state.isloading = true
            state.message = ''
        },
        [getAnswers.fulfilled]: (state, action) => {
            state.isloading = false;
            state.message = ''
            state.answers = action.payload
        },
        [getAnswers.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },
        //Insert Question
        [createQuestion.pending]: (state, action) => {
            state.isloading = true
        },
        [createQuestion.fulfilled]: (state, action) => {
            state.isloading = false;
            state.message = action.payload.message
            state.questions = [...state.questions, action.payload]
        },
        [createQuestion.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },
        //Insert Answer
        [createAnswer.pending]: (state, action) => {
            state.isloading = true
        },
        [createAnswer.fulfilled]: (state, action) => {
            state.isloading = false;
            state.message = action.payload.message
            state.answers = [...state.answers, action.payload.data]
        },
        [createAnswer.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },
        //Update Question
        [updateQuestion.pending]: (state, action) => {
            state.isloading = true
        },
        [updateQuestion.fulfilled]: (state, action) => {
            state.isloading = false;
            state.message = action.payload.message
        },
        [updateQuestion.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },
        //Update Answer
        [updateAnswer.pending]: (state, action) => {
            state.isloading = true
        },
        [updateAnswer.fulfilled]: (state, action) => {
            state.isloading = false;
            state.message = action.payload.message
        },
        [updateAnswer.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },
        // Get Question Id
        [getNewQuestionId.pending]: (state, action) => {
            state.isloading = true
            state.message = ''
        },
        [getNewQuestionId.fulfilled]: (state, action) => {
            state.isloading = false;
            state.questionId = action.payload;
            state.message = ''
        },
        [getNewQuestionId.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },
        //Delete Question
        [deleteQuestion.pending]: (state, action) => {
            state.isloading = true
        },
        [deleteQuestion.fulfilled]: (state, action) => {
            state.isloading = false;
            state.message = action.payload.message
            state.questions = state.questions.filter(el => el.question_id != action.payload.question_id)
        },
        [deleteQuestion.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },
        //Delete Question
        [deleteAnswer.pending]: (state, action) => {
            state.isloading = true
        },
        [deleteAnswer.fulfilled]: (state, action) => {
            state.isloading = false;
            state.message = action.payload.message
            state.answers = state.answers.filter(el => el.answer_id != action.payload.answer_id)
        },
        [deleteAnswer.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },
    }
})


export const { delAllQuestions, delMessage } = questionsByQuizSlice.actions
export default questionsByQuizSlice.reducer