import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CurdTokenervice from './../../services/axios/CurdTokenervice'
import { setMessage } from './message'

export const addLoginData = createAsyncThunk('add/addLoginData', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { token,userSchoolId, page } = arg
    try {
        const res = await CurdTokenervice.create(token, "addlogin",{
            userSchoolId,
            page
        })
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})

export const getCountLogin = createAsyncThunk('count/getLogin', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
   
    try {
        const res = await CurdTokenervice.getAll("countlgin");
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})

export const getLoginData = createAsyncThunk('get/loginData', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
   
    try {
        const res = await CurdTokenervice.getAll("logindata");
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})

const loginSlice = createSlice({
    name: 'getLogin',
    initialState: { isloading: false, login_data: [], message: '' },
 
    extraReducers: {
         // Get Count Login
         [getCountLogin.pending]: (state, action) => {
            state.isloading = true
            state.message = ''
        },
        [getCountLogin.fulfilled]: (state, action) => {
            state.isloading = false;
            state.login_data = action.payload;
            state.message = ''
        },
        [getCountLogin.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },

        // Add Login Data
        [getLoginData.pending]: (state, action) => {
            state.isloading = true
            state.message = ''
        },
        [getLoginData.fulfilled]: (state, action) => {
            state.isloading = false;
            state.login_data = action.payload;
            state.message = ''
        },
        [getLoginData.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },
          // Get Login Data
          [getCountLogin.pending]: (state, action) => {
            state.isloading = true
            state.message = ''
        },
        [getCountLogin.fulfilled]: (state, action) => {
            state.isloading = false;
            state.login_data = action.payload;
            state.message = ''
        },
        [getCountLogin.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },


    }
})

export default loginSlice.reducer