import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import CurdService from '../../admin/adminServices/curd.services'
import { setMessage } from './message'

const modle = "imgdata"

export const createImgData = createAsyncThunk(
    'imgData/addImg', async (arg, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        try {
            const res = await CurdService.create(modle, { imgUrl: arg.imgUrl, imgDesc: arg.imgDesc })
            return (res.data)
        }
        catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })

    export const retrieveImgDatas = createAsyncThunk(
        'imgData/getImgs', async (_, thunkAPI) => {
            const { rejectWithValue } = thunkAPI
            try {
                const res = await CurdService.getAll(modle);
                return (res.data)
            }catch(error){
                const message = error.message
                thunkAPI.dispatch(setMessage(message))
                return rejectWithValue(error.message)
            }
        })

export const retrieveImgDataById = createAsyncThunk(
    'imgData/retrieveImgById', async (id, thunkAPI) => {
      const { rejectWithValue } = thunkAPI
      try{
        const res = await CurdService.get(modle, id);
        return (res.data)
      }catch(error){
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
      }
    })

export const updateImgData = createAsyncThunk(
    'imgData/updateImg', async (arg,thunkAPI)=>{
        const { rejectWithValue } = thunkAPI
        const { id, data } = arg
        try{
            const res = await CurdService.update(modle, id, data)
            return (res.data)
        }catch(error){
      const message = error.message
      thunkAPI.dispatch(setMessage(message))
      return rejectWithValue(error.message)
        }
    })

export const deleteImgData = createAsyncThunk(
    'imgData/delImg', async (arg, thunkAPI)=>{
    const { rejectWithValue} = thunkAPI
    const {id, imgUrl} = arg
    try{
        const res = await CurdService.remove(modle, `${id}`)
        await CurdService.removeImg(imgUrl)
        return (res.data)
    }catch(error){
      const message = error.message
      thunkAPI.dispatch(setMessage(message))
      return rejectWithValue(error.message)
    }
})

const imgDataSlice = createSlice({
    name: 'imgdata',
    initialState: { isloading: false, img: [], message: '' },
    reducers:{
      clearCatMessage:(state,action)=>{
       state.message = ''
      },
      addImgData:(state, action)=>{
        state.img = state.img.push(action.payload)
      }
    },
    extraReducers: {
      // Create ImgData
      [createImgData.pending]: (state, action) => {
        state.isloading = true
      },
      [createImgData.fulfilled]: (state, action) => {
        state.isloading = false;
          state.img = action.payload;
      },
      [createImgData.rejected]: (state, action) => {
        state.isloading = false
        state.message = action.payload
      },
      // Get All ImgData
      [retrieveImgDatas.pending]: (state, action) => {
        state.isloading = true
      },
      [retrieveImgDatas.fulfilled]: (state, action) => {
        state.isloading = false;
        if (Object.keys(action.payload).length !== 0) {
          state.img = action.payload;
        }
      },
      [retrieveImgDatas.rejected]: (state, action) => {
        state.isloading = false
        state.message = action.payload
      },
      // delete ImgData
      [deleteImgData.pending]: (state, action) => {
        state.isloading = true
      },
      [deleteImgData.fulfilled]: (state, action) => {
        state.isloading = false;
/*         state.img = state.img.filter(item => item.id !== action.payload.id)
 */      },
      [deleteImgData.rejected]: (state, action) => {
        state.isloading = false
        state.message = action.payload
      },
      //retrieve One ImgData By Id
      [retrieveImgDataById.pending]: (state, action) => {
        state.isloading = true
      },
      [retrieveImgDataById.fulfilled]: (state, action) => {
        state.isloading = false;
        if (Object.keys(action.payload).length !== 0) {
          state.img = action.payload;
        }
      },
      [retrieveImgDataById.rejected]: (state, action) => {
        state.isloading = false
        state.message = action.payload
      },
      //Update ImgData 
      [updateImgData.pending]: (state, action) => {
        state.isloading = true
      },
      [updateImgData.fulfilled]: (state, action) => {
        state.isloading = false;
        if(action.payload.message){
          state.message = action.payload.message
        }else{
          return
        }
      },
      [updateImgData.rejected]: (state, action) => {
        state.isloading = false
        state.message = action.payload
      },
    }
  })
  
  export const { clearCatMessage, addImgData } = imgDataSlice.actions
  export default imgDataSlice.reducer