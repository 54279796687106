import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message"
import AuthService from "../../services/auth.service"

const user = JSON.parse(localStorage.getItem("user"))

export const register = createAsyncThunk("auth/register",
    async ({ username, email }, thunkAPI) => {
        try {
            const response = await AuthService.register(username, email)
            thunkAPI.dispatch(setMessage(response.data.message))
            return response.data
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            thunkAPI.dispatch(setMessage(message))
            return thunkAPI.rejectWithValue()
        }
    }
)

export const login = createAsyncThunk("auth/login",
    async ({ username, password }, thunkAPI) => {
        try {
            const data = await AuthService.login(username, password)
            return { user: data }
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            thunkAPI.dispatch(setMessage(message))
            return thunkAPI.rejectWithValue()
        }
    }
);


export const logout =  createAsyncThunk("auth/logout", async () => {
     AuthService.logout()
})

export const logoutPwa =  createAsyncThunk("auth/logout", async () => {
    AuthService.logoutPwa()
})

export const refreshToken =  createAsyncThunk("auth/logout", async () => {
})

// Reducer
const initialState = user
    ? { isLoggedIn: true, loading: false, user }
    : { isLoggedIn: false, loading: false, user: null }

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearMessage: (state, action) => {
            state.message = ''
        }
    },
    extraReducers: {
        // Register
        [register.pending]:(state, action)=>{
            state.loading = true
        },
        [register.fulfilled]: (state, action) => {
            state.isLoggedIn = false
            state.loading = false
        },
        [register.rejected]: (state, action) => {
            state.isLoggedIn = false
            state.loading = false
        },
        // LogIn
        [login.pending]:(state, action)=>{
            state.loading = true
        },
        [login.fulfilled]: (state, action) => {
            state.isLoggedIn = true
            state.loading = false
            state.user = action.payload.user
        },
        [login.rejected]: (state, action) => {
            state.isLoggedIn = false
            state.user = null
            state.loading = false
        },
        //LogOut
        [logout.fulfilled]: (state, action) => {
            state.isLoggedIn = false
            state.user = null
        },
    },
})

export const { clearMessage } = authSlice.actions
export default authSlice.reducer
