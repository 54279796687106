import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setMessage } from '../../store/slices/message'
import CurdTokenervice from '../../services/axios/CurdTokenervice'


export const createQuiz = createAsyncThunk(
    'quiz/insertquiz', async (arg, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        const { token, data } = arg
        try {
            const res = await CurdTokenervice.create(token, "quiz", data);
            return (res.data)
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })


export const getQuizzesTitle = createAsyncThunk('quiz/getquizTit', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { token, gredId, subjectId } = arg
    try {
        const res = await CurdTokenervice.getCourseTitles(token, "coursestitle", gredId, subjectId);
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})

export const getAllQuizzes = createAsyncThunk('quiz/getAllquizzes', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { token, gredId, subjectId } = arg
    try {
        const res = await CurdTokenervice.getDtatByGrade(token, "getquizbygrade", gredId, subjectId);
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})

export const updateQuiz = createAsyncThunk(
    'quiz/updtateQuiz', async (arg, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        const { token,quizId, couresId, gradeId, subjectId, termId, data } = arg
        try {
            const res = await CurdTokenervice.updateQuiz(token,quizId, couresId, gradeId, subjectId, termId, data);
            return (res.data)
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })

export const deleteQuiz = createAsyncThunk(
    'quiz/deletequiz', async (arg, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        const { token, quizId,couresId, gradeId, subjectId, termId } = arg
        try {
            const res = await CurdTokenervice.delQuiz(token, quizId,couresId, gradeId, subjectId, termId);
            return (res.data)
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })

export const getNewQuizId = createAsyncThunk('quiz/getquizId', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { token, couresId, gredId, subjectId, termId } = arg
    try {
        const res = await CurdTokenervice.getQuizId(token, couresId, gredId, subjectId, termId);
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})
const QuizByGradeSlice = createSlice({
    name: 'quiz',
    initialState: { isloading: false, quizzes: [], message: '', couresTitles: [], quizId: [] },
    reducers: {
        delAllQuizzes: (state, action) => {
            state.message = ''
            state.quizzes = []
            state.quizId = []
            state.couresTitles = []
        },
        delMessage: (state, action) => {
            state.message = ''
        }
    },
    extraReducers: {
        // Get Quizzes Title
        [getQuizzesTitle.pending]: (state, action) => {
            state.isloading = true
            state.message = ''
        },
        [getQuizzesTitle.fulfilled]: (state, action) => {
            state.isloading = false;
            state.couresTitles = action.payload;
            state.message = ''
        },
        [getQuizzesTitle.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },

        // Get All Quizzes
        [getAllQuizzes.pending]: (state, action) => {
            state.isloading = true
            state.message = ''
        },
        [getAllQuizzes.fulfilled]: (state, action) => {
            state.isloading = false;
            state.quizzes = action.payload;
            state.message = ''
        },
        [getAllQuizzes.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },

        //Insert Quiz
        [createQuiz.pending]: (state, action) => {
            state.isloading = true
        },
        [createQuiz.fulfilled]: (state, action) => {
            state.isloading = false;
            state.message = action.payload.message
            state.vocabularies = state.quizzes[0].quizzes.push(action.payload.data)
        },
        [createQuiz.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },

        //Update Quiz
        [updateQuiz.pending]: (state, action) => {
            state.isloading = true
        },
        [updateQuiz.fulfilled]: (state, action) => {
            state.isloading = false;
            state.message = action.payload.message

        },
        [updateQuiz.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },

        //Delete Quiz
        [deleteQuiz.pending]: (state, action) => {
            state.isloading = true
        },
        [deleteQuiz.fulfilled]: (state, action) => {
            state.isloading = false;
            state.message = action.payload.message
            // state.vocabularies = state.vocabularies[0].vocabularies.filter(el => el.vocabulary_Id != action.payload.vocabulary_Id)
        },
        [deleteQuiz.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },
        // Get Quiz Id
        [getNewQuizId.pending]: (state, action) => {
            state.isloading = true
            state.message = ''
        },
        [getNewQuizId.fulfilled]: (state, action) => {
            state.isloading = false;
            state.quizId = action.payload;
            state.message = ''
        },
        [getNewQuizId.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },

    }
})


export const { delAllQuizzes, delMessage } = QuizByGradeSlice.actions
export default QuizByGradeSlice.reducer