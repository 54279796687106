import React, { lazy } from 'react'
import { BrowserRouter } from "react-router-dom";
import { createRoot } from 'react-dom/client'
import './index.css'
//import App from './App'
import { Provider } from 'react-redux'
import store from './store/store'
import setupInterceptors from "./services/setupInterceptors";
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import swDev from './swDev';
const root = createRoot(document.getElementById('root'))
const App = lazy(()=>import('./App'))


root.render(
  <React.Fragment>
    <Provider store={store}>
      <BrowserRouter>
          <App />
        
      </BrowserRouter>
    </Provider>
  </React.Fragment>

)
//serviceWorkerRegistration.register();

setupInterceptors(store);
swDev();