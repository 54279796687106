import { createSlice } from "@reduxjs/toolkit";

const initialState = { oldhistory:'/' }

const historySlice = createSlice({
    name: "history",
    initialState,
    reducers: {
        sethistory: (state, action) => {
            return {oldhistory: action.payload}
        },
        clearhistory: () => {
            return { oldhistory: "/" }
        }
    }
})

export const { sethistory, clearhistory } = historySlice.actions;
export default historySlice.reducer;