import axios from "axios"

const API_URL = process.env.REACT_APP_SERVER_URL

let user = JSON.parse(localStorage.getItem("user"));
let accessToken = ''

if (!user) {
    accessToken = null
} else {
    accessToken = user.accessToken
}


const apiRefreshToken=(token = accessToken)=>{
    const instance = axios.create({
      baseURL: API_URL,
      headers: {
        "Content-Type": "application/json",
        'x-access-token': token 
      },
    })
    return instance
  }
  
  export default apiRefreshToken
