import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setMessage } from '../../store/slices/message'
import CurdTokenervice from '../../services/axios/CurdTokenervice'

export const createSubpart = createAsyncThunk(
    'subpartgrd/insertsubpart', async (arg, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        const { token, data } = arg
        try {
            const res = await CurdTokenervice.create(token, "subpart", data);
            return (res.data)
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })


export const getCoursesTitle = createAsyncThunk('subpartgrd/getcoursTit', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { token, gredId, subjectId } = arg
    try {
        const res = await CurdTokenervice.getCourseTitles(token, "coursestitle", gredId, subjectId);
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})

export const getAllSubParts = createAsyncThunk('subpartgrd/getAllSubParts', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { token, gredId, subjectId } = arg
    try {
        const res = await CurdTokenervice.getSubpartsByGrade(token, "getsubpartbygrade", gredId, subjectId);
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})

export const updateSubpart = createAsyncThunk(
    'subpartgrd/updtateSubPart', async (arg, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        const { token, subpartId, data } = arg
        try {
            const res = await CurdTokenervice.updateSubpart(token, "subpart", subpartId, data);
            return (res.data)
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })


export const deleteSubpart = createAsyncThunk(
    'subpartgrd/deleteSubPart', async (arg, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        const { token, subpartId } = arg
        try {
            const res = await CurdTokenervice.deleteSubpart(token, "subpart", subpartId);
            return (res.data)
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })


const subpartByGradeSlice = createSlice({
    name: 'subpartgrd',
    initialState: { isloading: false, subparts: [], message: '', couresTitles: [] },
    reducers: {
        delAllSubParts: (state, action) => {
            state.message = ''
            state.subparts = []
            state.couresTitles = []
        },
        delMessage: (state, action) => {
            state.message = ''
        }
    },
    extraReducers: {
        // Get Subpart Title
        [getCoursesTitle.pending]: (state, action) => {
            state.isloading = true
            state.message = ''
        },
        [getCoursesTitle.fulfilled]: (state, action) => {
            state.isloading = false;
            state.couresTitles = action.payload;
            state.message = ''
        },
        [getCoursesTitle.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },

        // Get All Subparts
        [getAllSubParts.pending]: (state, action) => {
            state.isloading = true
            state.message = ''
        },
        [getAllSubParts.fulfilled]: (state, action) => {
            state.isloading = false;
            state.subparts = action.payload;
            state.message = ''
        },
        [getAllSubParts.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },

        //Insert Subpart
        [createSubpart.pending]: (state, action) => {
            state.isloading = true
        },
        [createSubpart.fulfilled]: (state, action) => {
            state.isloading = false;
            state.message = action.payload.message
        },
        [createSubpart.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },

        //Update Subpart
        [updateSubpart.pending]: (state, action) => {
            state.isloading = true
        },
        [updateSubpart.fulfilled]: (state, action) => {
            state.isloading = false;
            state.message = action.payload.message

        },
        [updateSubpart.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },
        //Delete Subpart
        [deleteSubpart.pending]: (state, action) => {
            state.isloading = true
        },
        [deleteSubpart.fulfilled]: (state, action) => {
            state.isloading = false;
            state.message = action.payload.message
            state.subparts = state.subparts.filter(el => el.subpart_id != action.payload.subpart_id)
        },
        [deleteSubpart.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },

    }
})

export const { delAllSubParts, delMessage } = subpartByGradeSlice.actions
export default subpartByGradeSlice.reducer