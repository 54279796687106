const  swDev=()=>{

    let swUrl = `${process.env.PUBLIC_URL}/sw.js`
    if ('serviceWorker' in navigator) { 
      console.log('register serviceWorker')
        navigator.serviceWorker.register(swUrl)
         
          .catch(error => {
            console.error('Service worker registration failed:', error);
          });
    }
}
export default swDev