import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CurdService from '../../admin/adminServices/curd.services'
import { setMessage } from './message'

export const getCountVisit = createAsyncThunk('count/getVisits', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
   
    try {
        const res = await CurdService.getAll("countvisit");
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})


const countVisitsSlice = createSlice({
    name: 'getVisits',
    initialState: { isloading: false, visits: [], message: '' },
 
    extraReducers: {
        // Get Count Visits
        [getCountVisit.pending]: (state, action) => {
            state.isloading = true
            state.message = ''
        },
        [getCountVisit.fulfilled]: (state, action) => {
            state.isloading = false;
            state.visits = action.payload;
            state.message = ''
        },
        [getCountVisit.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },
    }
})



export default countVisitsSlice.reducer