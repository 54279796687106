class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("user"))
    return user?.refreshToken
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("user"))
    return user?.accessToken
  }


  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem("user"))
    user.accessToken = token
    localStorage.setItem("user", JSON.stringify(user))
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user"))
  }

  setUser(user) {
    localStorage.setItem("user", JSON.stringify(user))
  }

  
  setStudentToken(data) {
    localStorage.setItem("user", JSON.stringify(data))
    let user = JSON.parse(localStorage.getItem("user"))
    user.accessToken = data.accessToken
    localStorage.setItem("user", JSON.stringify(user))
  }




  setOsraData(osraData) {
    localStorage.setItem("osraData", JSON.stringify(osraData))
    localStorage.setItem("loogedOsra", false)
  }

  removeUser() {
    localStorage.removeItem("user")
    localStorage.removeItem("login_Data")
    //localStorage.removeItem("osraData")
    localStorage.setItem("loogedOsra", false)
  }
  removePwaUser() {
    localStorage.removeItem("user")
    localStorage.removeItem("login_Data")
    localStorage.removeItem("osraData")
    localStorage.setItem("loogedOsra", false)
  }
}

export default new TokenService()