import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setMessage } from '../../store/slices/message'
import CurdTokenervice from '../../services/axios/CurdTokenervice'

export const createCourse = createAsyncThunk(
    'coursebygrd/insertCourse', async (arg, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        const { token, data } = arg
        try {
            const res = await CurdTokenervice.create(token, "course", data);
            return (res.data)
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })


export const getTopicsTitle = createAsyncThunk('coursebygrd/getTopicTit', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { token, gredId, subjectId } = arg
    try {
        const res = await CurdTokenervice.getTopicsTitles(token, "topicstitle", gredId, subjectId);
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})

export const getAllCourses = createAsyncThunk('coursebygrd/getAllCourse', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { token, gredId, subjectId } = arg 
    try {
        const res = await CurdTokenervice.getCoursesByGrade(token, "getcoursebygrade", gredId, subjectId);
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})

export const updateCourse = createAsyncThunk(
    'coursebygrd/updtateCourse', async (arg, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        const { token, courseId, gredId, subejectId, termId, data } = arg
        try {
            const res = await CurdTokenervice.updateCourse(token, "course", courseId, gredId, subejectId, termId, data);
            return (res.data)
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })


    export const deleteCourse = createAsyncThunk(
        'coursebygrd/deleteCourse', async (arg, thunkAPI) => {
            const { rejectWithValue } = thunkAPI
            const { token, courseId, gredId, subejectId, termId } = arg
            try {
                const res = await CurdTokenervice.deleteCourse(token, "course", courseId, gredId, subejectId, termId);
                return (res.data)
            } catch (error) {
                const message = error.message
                thunkAPI.dispatch(setMessage(message))
                return rejectWithValue(error.message)
            }
        })


const coursesByGradeSlice = createSlice({
    name: 'coursebygrd',
    initialState: { isloading: false, courses: [], message: '' ,topicTitles:[]},
    reducers: {
        delAllCourses: (state, action) => {
            state.message = ''
            state.courses =[]
            state.topicTitles =[]
        },
        delMessage:(state, action)=>{
            state.message = ''
        }
    },
    extraReducers: {
         // Get Topics Title
         [getTopicsTitle.pending]: (state, action) => {
            state.isloading = true
            state.message = ''
        },
        [getTopicsTitle.fulfilled]: (state, action) => {
            state.isloading = false;
            state.topicTitles = action.payload;
            state.message = ''
        },
        [getTopicsTitle.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },

        // Get All Courses
        [getAllCourses.pending]: (state, action) => {
            state.isloading = true
            state.message = ''
        },
        [getAllCourses.fulfilled]: (state, action) => {
            state.isloading = false;
            state.courses = action.payload;
            state.message = ''
        },
        [getAllCourses.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },

         //Insert Course
         [createCourse.pending]: (state, action) => {
            state.isloading = true
        },
        [createCourse.fulfilled]: (state, action) => {
            state.isloading = false;
            state.message = action.payload.message
            //state.courses.push(action.payload)
            
        },
        [createCourse.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },

        //Update Course
        [updateCourse.pending]: (state, action) => {
            state.isloading = true
        },
        [updateCourse.fulfilled]: (state, action) => {
            state.isloading = false;
            state.message = action.payload.message
            
        },
        [updateCourse.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload 
        },
         //Delete Topic
         [deleteCourse.pending]: (state, action) => {
            state.isloading = true
        },
        [deleteCourse.fulfilled]: (state, action) => {
            state.isloading = false;
            state.message = action.payload.message
            state.courses =  state.courses.filter(el=>el.course_id != action.payload.course_id)
        },
        [deleteCourse.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },

    }
})

export const { delAllCourses,delMessage } = coursesByGradeSlice.actions
export default coursesByGradeSlice.reducer