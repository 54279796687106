import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CurdService from './../../admin/adminServices/curd.services'
import { setMessage } from '../../store/slices/message'

export const getImgListData = createAsyncThunk('imgListData/getImges',async(id,thunkAPI)=>{
    const { rejectWithValue } = thunkAPI
    try{
        const res = await CurdService.get("listimages", id);
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    }catch(error){
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})

const imgListDataSlice = createSlice({
    name:'imgListData',
    initialState:{isloading:false,img:[],message:''},
    extraReducers:{
        // Get Images
        [getImgListData.pending]:(state, action)=>{
            state.isloading = true
        },
        [getImgListData.fulfilled]:(state, action)=>{
           state.isloading = false;
          if(Object.keys(action.payload).length !== 0 ){
             state.img = action.payload;
          }
        },
        [getImgListData.rejected]:(state, action)=>{
            state.isloading = false
           state.message = action.payload
    }
    }

})
export default imgListDataSlice.reducer