import TokenService from "./token.service";
import api from "./api";

class AuthService {
  register = (username, password) => {
    return api.post("auth/signup", {
      username,
      password,
    });
  };

  login = (username, password) => {
    return api
      .post("auth/signin", {
        username,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          TokenService.setUser(response.data);
          const osraData = response.data
          const data ={
            id : osraData.id,
            firstName: osraData.firstName,
            username: osraData.username,
            roles: osraData.roles,
            students: osraData.students,
          }
          TokenService.setOsraData(data)
        }

        return response.data
      })
  }

  osraLogin = (username, password) => {
    return api
      .post("auth/osrasingin", {
        username,
        password,
      })
      .then((response) => {
        if (response.data) {
          TokenService.setOsraData(response.data);
        }

        return response.data
      })
  }

  studentReLogin = async(username, password,firstName,fulltName,stdClass, stdGender,stdGrade,student_Id) => {
    return api
      .post("auth/signin", {
        username,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
         
          const data = {
            username,
            accessToken :response.data.accessToken,
            roles:["ROLE_STUDENT"],
            firstName,
            fulltName,
            stdClass,
            stdGender,
            stdGrade,
            userSchoolId:student_Id,
          }
          TokenService.setStudentToken(data);
        }

        return response.data
      })
  }


  logout = () => {
    TokenService.removeUser();
  }

  logoutPwa = () => {
    TokenService.removePwaUser();
  }
  getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  };
}
export default new AuthService()
