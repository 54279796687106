import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import message, { setMessage } from '../../store/slices/message'
import CurdTokenervice from '../../services/axios/CurdTokenervice'


export const getAllTopics = createAsyncThunk('topics/getAllTopics', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { token, gredId, subjectId } = arg
    try {
        const res = await CurdTokenervice.getAllTopics(token, "alltopics", gredId, subjectId);
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})


export const getTopics = createAsyncThunk('topics/getTopics', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { token, gredId, termId, subjectId } = arg
    try {
        const res = await CurdTokenervice.getTopics(token, "topics", gredId, termId, subjectId);
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})

export const createTopic = createAsyncThunk(
    'topics/insertTopic', async (arg, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        const { token, data } = arg
        try {
            const res = await CurdTokenervice.create(token, "topic", data);
            return (res.data)
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })

export const updateTopic = createAsyncThunk(
    'topics/updtateTopic', async (arg, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        const { token, topicId, gredId, subejectId, termId, data } = arg
        try {
            const res = await CurdTokenervice.updateTopic(token, "topic", topicId, gredId, subejectId, termId, data);
            return (res.data)
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })


    export const deleteTopic = createAsyncThunk(
        'topics/deleteTopic', async (arg, thunkAPI) => {
            const { rejectWithValue } = thunkAPI
            const { token, topicId, gredId, subejectId, termId } = arg
            try {
                const res = await CurdTokenervice.deleteTopic(token, "topic", topicId, gredId, subejectId, termId);
                return (res.data)
            } catch (error) {
                const message = error.message
                thunkAPI.dispatch(setMessage(message))
                return rejectWithValue(error.message)
            }
        })

const topicsSlice = createSlice({
    name: 'topics',
    initialState: { isloading: false, topics: [], message: '' },
    reducers: {
        delTopics: (state, action) => {
            state.message = ''
            state.topics = []
        },
        delMessage: (state, action) => {
            state.message = ''
        }
    },
    extraReducers: {
        // Get All Topics
        [getAllTopics.pending]: (state, action) => {
            state.isloading = true
            state.message = ''
        },
        [getAllTopics.fulfilled]: (state, action) => {
            state.isloading = false;
            state.topics = action.payload;
            state.message = ''
        },
        [getAllTopics.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
            console.log(message)
        },

        // Get Topics
        [getTopics.pending]: (state, action) => {
            state.isloading = true
            state.message = ''
        },
        [getTopics.fulfilled]: (state, action) => {
            state.isloading = false;
            state.topics = action.payload;
            state.message = ''
        },
        [getTopics.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },

         //Insert Topic
         [createTopic.pending]: (state, action) => {
            state.isloading = true
        },
        [createTopic.fulfilled]: (state, action) => {
            state.isloading = false;
            state.topics.push(action.payload)
            
        },
        [createTopic.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },

        //Update Topic
        [updateTopic.pending]: (state, action) => {
            state.isloading = true
        },
        [updateTopic.fulfilled]: (state, action) => {
            state.isloading = false;
            state.message = action.payload.message
            
        },
        [updateTopic.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload 
        },

        //Delete Topic
        [deleteTopic.pending]: (state, action) => {
            state.isloading = true
        },
        [deleteTopic.fulfilled]: (state, action) => {
            state.isloading = false;
            state.message = action.payload.message
            state.topics =  state.topics.filter(el=>el.topic_id != action.payload.topic_id)
        },
        [deleteTopic.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },
    }
})

export const { delTopics, delMessage } = topicsSlice.actions
export default topicsSlice.reducer