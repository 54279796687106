import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import CurdService from '../../admin/adminServices/curd.services'
import { setMessage } from './message'

const modle = "imgcatogery"

export const createImgCatogery = createAsyncThunk(
  'imgcatogery/addImg', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    try {
      const res = await CurdService.create(modle, { title: arg.title, catDesc: arg.catDesc })
      return (res.data)

    } catch (error) {
      const message = error.message
      thunkAPI.dispatch(setMessage(message))
      return rejectWithValue(error.message)
    }
  })


export const retrieveImgCatogeries = createAsyncThunk(
  'imgcatogery/getImgs', async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    try {
      const res = await CurdService.getAll(modle);
      return res.data
    } catch (error) {
      const message = error.message
      thunkAPI.dispatch(setMessage(message))
      return rejectWithValue(error.message)
    }
  })

export const deleteImgCatogery = createAsyncThunk(
  'imgcatogery/delImg', async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    try {
      const catData = await CurdService.get('imgcatdata', id)
      if (catData.length != 0 && catData.data.length != 0) {
        catData.data.map(async cat => {
          await CurdService.removeImg(cat.imgUrl)
          await CurdService.remove('imgdata',cat.id)
        })
      }
      await CurdService.remove(modle, `${id}`)
      return ("OK")
    } catch (error) {
      const message = error.message
      thunkAPI.dispatch(setMessage(message))
      return rejectWithValue(error.message)
    }
  })

export const retrieveImgCatogeryById = createAsyncThunk(
  'imgcatogery/retrieveImgById', async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    try {
      const res = await CurdService.get(modle, id);
      return (res.data)

    } catch (error) {
      const message = error.message
      thunkAPI.dispatch(setMessage(message))
      return rejectWithValue(error.message)
    }
  })

export const updateImgCatogery = createAsyncThunk(
  'imgcatogery/updateImg', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { id, data } = arg
    try {
      const res = await CurdService.update(modle, id, data);
      return (res.data)

    } catch (error) {
      const message = error.message
      thunkAPI.dispatch(setMessage(message))
      return rejectWithValue(error.message)
    }
  })

const imgCatogerySlice = createSlice({
  name: 'imgcatogery',
  initialState: { isloading: false, img: [], message: '' },
  reducers: {
    clearCatMessage: (state, action) => {
      state.message = ''
    }
  },
  extraReducers: {
    // Create ImgCategory
    [createImgCatogery.pending]: (state, action) => {
      state.isloading = true
    },
    [createImgCatogery.fulfilled]: (state, action) => {
      state.isloading = false;
      state.img = action.payload;
    },
    [createImgCatogery.rejected]: (state, action) => {
      state.isloading = false
      state.message = action.payload
    },
    // Get All ImgCatogery
    [retrieveImgCatogeries.pending]: (state, action) => {
      state.isloading = true
    },
    [retrieveImgCatogeries.fulfilled]: (state, action) => {
      state.isloading = false;
      if (Object.keys(action.payload).length !== 0) {
        state.img = action.payload;
      }
    },
    [retrieveImgCatogeries.rejected]: (state, action) => {
      state.isloading = false
      state.message = action.payload
    },
    // delete ImgCatogery
    [deleteImgCatogery.pending]: (state, action) => {
      state.isloading = true
    },
    [deleteImgCatogery.fulfilled]: (state, action) => {
      state.isloading = false;
      //state.img = state.img.filter(item => item.id !== action.payload.id)
    },
    [deleteImgCatogery.rejected]: (state, action) => {
      state.isloading = false
      state.message = action.payload
    },
    //retrieve One ImgCatogery By Id
    [retrieveImgCatogeryById.pending]: (state, action) => {
      state.isloading = true
    },
    [retrieveImgCatogeryById.fulfilled]: (state, action) => {
      state.isloading = false;
      if (Object.keys(action.payload).length !== 0) {
        state.img = action.payload;
      }
    },
    [retrieveImgCatogeryById.rejected]: (state, action) => {
      state.isloading = false
      state.message = action.payload
    },
    //Update ImgCatogery 
    [updateImgCatogery.pending]: (state, action) => {
      state.isloading = true
    },
    [updateImgCatogery.fulfilled]: (state, action) => {
      state.isloading = false;
      if (action.payload.message) {
        state.message = action.payload.message
      } else {
        return
      }
    },
    [updateImgCatogery.rejected]: (state, action) => {
      state.isloading = false
      state.message = action.payload
    },
  }
})

export const { clearCatMessage } = imgCatogerySlice.actions
export default imgCatogerySlice.reducer