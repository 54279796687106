import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CurdTokenervice from '../../services/axios/CurdTokenervice'
import { setMessage } from './message'

export const getstudentDegrees = createAsyncThunk('stdDgree/getstdDgree', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const {token, model, stdId, testKindId } = arg
    try {
        const res = await CurdTokenervice.getDegree(token, model, stdId, testKindId);
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})

const studentDgreeSlice = createSlice({
    name: 'stdDgree',
    initialState: { isloading: false, degree: [], message: '' },
    reducers: {
        delDegreeMessage: (state, action) => {
            state.message = ''
        }
    },
    extraReducers: {
        // Get stutent Dgree
        [getstudentDegrees.pending]: (state, action) => {
            state.isloading = true
            state.message = ''
        },
        [getstudentDegrees.fulfilled]: (state, action) => {
            state.isloading = false;
            state.degree = action.payload;
            state.message = ''
        },
        [getstudentDegrees.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },
    }
})


export const {delDegreeMessage} = studentDgreeSlice.actions
export default studentDgreeSlice.reducer